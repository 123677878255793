import { useCallback, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useLatest } from 'hooks/useLatest';
import { salariesApiFetchJobSynonym } from 'modules/salaries/api/salariesApiFetchJobSynonym';
import { searchTrackDisplayedListings } from 'modules/search/helpers/searchTrackDisplayedListings';
import { useSearchReady } from 'modules/search/hooks/useSearchReady';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { SearchType } from 'modules/search/types/SearchType';
import { MainStoreState } from 'store/MainStoreState';
import { useMainDispatch } from 'store/hooks/useMainDispatch';
import { useMainSelector } from 'store/hooks/useMainSelector';
import { mainStoreFetchPage } from 'store/mainSearch/mainStoreFetchPage';
import { useModal } from 'zustand-stores/modalStore';

import { assignSearchPageUrlParametersToRedux } from './assignSearchPageUrlParametersToRedux';

type Args = {
  searchType: SearchType;
  searchFacets: SearchFacet[];
  getSearchTrackingData: () => Record<string, unknown>;
};

export function useSearchPageSyncUrlAndFetch({
  searchType,
  searchFacets,
  getSearchTrackingData,
}: Args) {
  const dispatch = useMainDispatch();

  const [searchParams] = useSearchParams();
  const getState = useStore().getState as () => MainStoreState;

  const searchLocation = useMainSelector(
    (state) => state.mainSearch.searchLocation,
  );

  const { mainSearchReady } = useSearchReady();

  const [initialized, setInitialized] = useState(false);

  const initializedRef = useLatest(initialized);

  /*
   * Load Job Synonym if necessary
   */
  const jobSynonymId = searchParams.get('synonymId');
  useEffect(() => {
    if (!jobSynonymId) return;

    const previousJobFamilyFacetData = getState().mainSearch.jobFamilyFacetData;
    if (previousJobFamilyFacetData?.jobSynonym.id === jobSynonymId) return;

    salariesApiFetchJobSynonym(jobSynonymId).then((jobFamilyFacetData) => {
      dispatch({
        type: 'SEARCH_SET_JOB_FAMILY_FACET_DATA',
        jobFamilyFacetData,
      });
    });
  }, [dispatch, getState, jobSynonymId]);

  /*
   * Assign params to redux & fetch results
   */

  // Don't trigger fetch just because the modal opened/closed
  const { modal } = useModal();
  const modalShownRef = useLatest(Boolean(modal));

  // Don't trigger fetch just because tracking data changed
  const getSearchTrackingDataRef = useLatest(getSearchTrackingData);

  const fetch = useCallback(async () => {
    assignSearchPageUrlParametersToRedux({
      getState,
      dispatch,
      searchType,
      searchParams,
      searchFacets,
    });

    const results = await dispatch(mainStoreFetchPage());

    if (!initializedRef.current) {
      setInitialized(true);
    }

    if (modalShownRef.current) return;

    searchTrackDisplayedListings(
      results.hits,
      getSearchTrackingDataRef.current(),
    );
  }, [
    dispatch,
    getSearchTrackingDataRef,
    getState,
    initializedRef,
    modalShownRef,
    searchFacets,
    searchParams,
    searchType,
  ]);

  /*
   * Re-fetch once url or search location change
   */
  useEffect(() => {
    if (!mainSearchReady) return;
    fetch();
  }, [
    fetch,
    mainSearchReady,
    // Trigger fetch on search location change
    searchLocation,
  ]);

  return { getSearchTrackingData, initialized };
}
